import { Component, TemplateRef } from '@angular/core';
import { InvoicePaymentsService } from './InvoicePayments.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { CONSTANTS } from '../CONSTANTS';
import { AppComponent } from '../../app.component';
import { InvoicePDFComponent } from '../InvoicePDF/InvoicePDF.component';
import { DateConversionsComponent } from '../Global/DateConversions';
import { GlobalConfig } from '../Global/GlobalConfig';
import { error } from '@angular/compiler/src/util';
declare var braintree: any;
declare var Stripe: any;

@Component({
    selector: 'app-invoicepayments',
    templateUrl: '../../views/InvoicePayments/InvoicePayments.component.html',
    providers: [InvoicePDFComponent, DateConversionsComponent, GlobalConfig]
})
export class InvoicePaymentsComponent {
    invoiceFeaturesList: any;
    invoiceChargesList: any;
    additionalChargesTotal: number = 0;
    totalInvoiceDiscount: any;
    invoiceTotalAmount: number = 0;
    paymentReceiptdetails: any;
    sixMonthPreviousDate: Date = new Date();
    endDateForPaymentHistoryFilter: Date = new Date(new Date().getFullYear(), (new Date().getMonth()) + 1, 0, 23, 59, 59);
    paymentHistoryDateDetails: any = {
        "StartDatePlaceholder": "Start Date",
        "StartDateMinDate": "",
        "StartDateMaxDate": new Date(),
        "StartDateModel": "",
        "EndDatePlaceholder": "End Date",
        "EndDateMinDate": "",
        "EndDateMaxDate": this.endDateForPaymentHistoryFilter,
        "EndDateModel": this.endDateForPaymentHistoryFilter
    };
    pendingInvoiceResponse: any = {};
    pendingInvoiceDetails: any = [];
    pendingInvoiceDetailsCopy: any = [];
    showPaymentReciept: boolean = false;
    paymentStatusClv: any = {
        "PYMNTOVERDUE_PMNTMSG": {
            "name": "Payment Overdue",
            "color": "abaError",
            "borderColor": "abaErrorBrdr",
            "title": "Payment Overdue!",
            "message": "You have charge(s) past overdue. Please pay the full amount to avoid suspension or termination of your AlohaABA account. If you have recently made a payment, please allow up to 24 hours to have the payment(s) reflect in the Invoices & Payments page before contacting Customer Support."
        },
        "PYMNTDUE_PMNTMSG": {
            "name": "Payment Due",
            "color": "abaError",
            "borderColor": "abaErrorBrdr",
            "title": "Payment Due!",
            "message": "You have charge(s) past due. Please pay the full amount to continue uninterrupted access to your AlohaABA account. If you have recently made a payment, please allow up to 24 hours to have the payment(s) reflect in the Invoices & Payments page before contacting Customer Support."
        },
        "PAST_DUE": {
            "name": "Past Payment Due",
            "color": "abaError",
            "borderColor": "abaErrorBrdr",
            "title": "Past Payment Due!",
          "message": "You have a charge that is past due. Please pay the full amount to continue uninterrupted access to your AlohaABA account. If you have recently made a payment, please allow up to 24 hours to have the payment(s) reflect in the Invoices & Payments page before contacting Customer Support."
        },
        "DUE": {
            "name": "Payment Due",
            "color": "abaSemiGray",
            "borderColor": "abaSemiGrayBrdr",
            "title": "Payment Due!",
          "message": "Your current invoice is ready!   If you have recently made a payment, please allow up to 24 hours to have the payment(s) reflect in the Invoices & Payments page before contacting Customer Support."
        }
    };
    paymentModeTypes: any = [{
        type: "visa",
        name: "Visa"
    }, {
        type: "paypal",
        name: "Paypal"
    }];
    paymentMode: any = this.paymentModeTypes[0];
    paymentModeType: any = this.paymentModeTypes[0];
    BRAINTREE_JWT_TOKEN: any = "";
    paymentConfirmationDetails: any = {};
    paymentInstance: any = {};
    paymentErrorOccurred: boolean = true;
    isCardPayment: boolean = true;
    showPendingInvoices: boolean = true;
    stripeObject: any = {};
    StripePublishableKey: any = "";
    alertMessageDetails: any = {};
    paymentHistoryArray: any = [];
    noRecordsAvailable: boolean;
    dialogReferences: any = {};
    invoiceResponse: any = {};
    hidePdf: boolean = true;
    invoiceDate: any = '';
    invoiceMonth: any = '';
    invoiceDueDate: any = '';
    customerCardDetails: any = [];
    CustomerPaymentKeyDetails: any = [];
    selectedPendingInvoice: any = {};
    staticData: any = {
      paymentLoadingGifName: "aba_Loader.gif",    
      alohaLoadingGifName: "aloha_Loader.gif",    
      paymentLoading: "Please wait while we process your payment."
    };
    subscriptionEmail: String = this.globalConfig.getCookie('UserName');
    constructor(private InvoicePaymentsService: InvoicePaymentsService, private dateConversions: DateConversionsComponent, private dialog: MatDialog, public invoicepdf: InvoicePDFComponent, private parent: AppComponent, private globalConfig: GlobalConfig) {
    }
    changeDate() {
        console.log("Changed Dte::::");
    }
    openPaymentPopup(templateRef: TemplateRef<any>, pendingInvoiceItem: any) {
      this.parent.currentPageLoading = true;
      this.parent.gif = this.staticData.alohaLoadingGifName;
      this.dialogReferences.paymentDialog = templateRef;
        this.dialogReferences.paymentDialogHandler = this.dialog.open(templateRef);
        this.selectedPendingInvoice = pendingInvoiceItem;
      //document.getElementById("paymentOptionsContainer").innerHTML = '';
        this.paymentMode = this.paymentModeTypes[0];
      this.changePaymentType(this.paymentMode, '');
    }
    openPaymentStatusDialog(templateRef: TemplateRef<any>) {
        this.dialogReferences.paymentSummaryDialog = templateRef;
        this.dialogReferences.paymentSummaryDialogHandler = this.dialog.open(templateRef);
    }

  // Generating Invoice PDF
  customiseInvoice(pendingResponse: any) {
      this.pendingInvoiceResponse = pendingResponse;
      this.invoiceFeaturesList = this.pendingInvoiceResponse.InvoiceItems;
      this.additionalChargesTotal = 0;
      if (this.pendingInvoiceResponse.InvoiceDetails && this.pendingInvoiceResponse.InvoiceAdditionalCharges) {
          if (!this.pendingInvoiceResponse.InvoiceDetails[0].DiscountAmount) {
              this.totalInvoiceDiscount = "Nil";
          }
          else {
              if (this.pendingInvoiceResponse.InvoiceDetails[0].Invoice_Discount_Name == '$') {
                  this.totalInvoiceDiscount = "$" + this.pendingInvoiceResponse.InvoiceDetails[0].DiscountAmount;
              }
              else {
                  this.totalInvoiceDiscount = this.pendingInvoiceResponse.InvoiceDetails[0].DiscountAmount + "%";
              }
          }
          if (this.pendingInvoiceResponse.InvoiceAdditionalCharges.length > 0) {
              for (var a = 0; a < this.pendingInvoiceResponse.InvoiceAdditionalCharges.length; a++) {
                  this.additionalChargesTotal = this.additionalChargesTotal + this.pendingInvoiceResponse.InvoiceAdditionalCharges[a].Amount;
              }
          }
          this.invoiceDate = this.dateConversions.convertDateToMMMDDYYYY(this.pendingInvoiceResponse.InvoiceDetails[0].Invoice_Generated_Date);
          this.invoiceDueDate = this.dateConversions.convertDateToMMMDDYYYY(this.pendingInvoiceResponse.InvoiceDetails[0].DueDate);
          this.invoiceMonth = this.dateConversions.convertDateToMMMM_YYYY(this.pendingInvoiceResponse.InvoiceDetails[0].Invoice_Generated_Date);
          this.invoiceChargesList = {
              totalAmount: this.pendingInvoiceResponse.InvoiceDetails[0].SubTotal,
              discountAmount: this.pendingInvoiceResponse.InvoiceDetails[0].DiscountAmount,
              totalInvoiceDiscount: this.totalInvoiceDiscount,
              additionalChargesTotal: this.additionalChargesTotal,
              totalAmountBilled: this.pendingInvoiceResponse.InvoiceDetails[0].AmountBilled,
              invoiceDate: this.invoiceDate,
              invoiceMonth: this.invoiceMonth,
              invoiceDueDate: this.invoiceDueDate
          }
          let totalResponse = {
              pendingInvoiceResponse: this.pendingInvoiceResponse,
              invoiceFeaturesList: this.invoiceFeaturesList,
              invoiceChargesList: this.invoiceChargesList
          }
          return totalResponse;
      }
      return null;
    }

  generateInvoice() {
      this.invoicepdf.generateInvoice(this.invoiceResponse.pendingInvoiceResponse, this.invoiceResponse.invoiceFeaturesList, this.invoiceResponse.invoiceChargesList);
  }
    paymentReceipt(paymentId: any, invoiceId: any) {
        this.invoicepdf.paymentReceipt(this.paymentReceiptdetails, this.invoiceResponse.pendingInvoiceResponse, this.invoiceResponse.invoiceFeaturesList, this.invoiceResponse.invoiceChargesList);
    }
    //Invoice Preview code by Anil
    openPendinginvoice(templateRef: TemplateRef<any>, invoiceId: any) {
        //this.InvoicePaymentsService.getPendingInvoice(CONSTANTS.SUBSCRIPTIONID)
        this.InvoicePaymentsService.getInvoiceById(invoiceId)
            .subscribe(response => {
                if (response && response.body) {
                    this.invoicepdf.hidePaymentreceiptDetails(this.showPaymentReciept);                   
                    this.pendingInvoiceResponse = response.body;
                    this.invoiceResponse = this.customiseInvoice(this.pendingInvoiceResponse);
                    this.dialog.open(templateRef);
                }
            }, error => { });
    }
    openHistoryinvoice(paymentId: any, invoiceId: any,templateRef: TemplateRef<any>) {
        this.InvoicePaymentsService.getPaymentDetailsById(paymentId).subscribe(response => {
            if (response && response.body) {
                this.paymentReceiptdetails = response.body;
                //this.InvoicePaymentsService.getInvoiceDetailsById(invoiceId).subscribe(response => {
                this.InvoicePaymentsService.getInvoiceById(invoiceId).subscribe(response => {
                    if (response && response.body) {
                        this.pendingInvoiceResponse = [];
                        this.pendingInvoiceResponse = response.body;
                        this.invoicepdf.showPaymentreceiptDetails(this.showPaymentReciept);
                        this.paymentReceiptdetails.EmailId = this.subscriptionEmail;
                        this.paymentReceiptdetails.TotalAmount = parseFloat(this.paymentReceiptdetails.TotalAmount).toFixed(2);
                        this.paymentReceiptdetails.PaymentDate = this.dateConversions.convertDateToMMMMDDYYYY(this.paymentReceiptdetails.PaymentDateTime);
                        this.paymentReceiptdetails.PaymentTime = this.dateConversions.convertDateToHMMSS(this.paymentReceiptdetails.PaymentDateTime);
                        this.invoiceResponse = this.customiseInvoice(this.pendingInvoiceResponse);
                        this.invoiceFeaturesList = this.invoiceResponse.invoiceFeaturesList;
                        this.invoiceChargesList = this.invoiceResponse.invoiceChargesList;
                        this.dialog.open(templateRef);
                    }
                },
                    error => { });
            }
        },
            error => { }); 
    }   
    //end of Invoice Preview 

    filterInvoices() {
      this.pendingInvoiceDetails = this.pendingInvoiceDetailsCopy.filter(item => {
          return this.showPendingInvoices ? item.IsPending : true;
      });
    }
    
    // On selecting payment mode type
    changePaymentType(paymentModeTypeItem: any, event: any) {
        if (paymentModeTypeItem.type == "visa") {
            this.isCardPayment = true;
            if (!event && (!this.StripePublishableKey || !this.stripeObject || !this.stripeObject.card)) {
                this.stripePayment();
            }
            else {
                setTimeout(() => {
                    this.initialiseStripe(this.StripePublishableKey);
                }, 300);
            }
        } else if (paymentModeTypeItem.type == "card" && paymentModeTypeItem.StripeCustomerId) {
            //
        }
        //else {
        //    this.isCardPayment = false;
        //    //document.getElementById("paymentOptionsContainer").innerHTML = '';
        //}
      this.paymentMode = paymentModeTypeItem;
    }

    stripePayment() {
        try {
            this.InvoicePaymentsService.getStripeKey().subscribe(response => {
                let responseObject = JSON.parse(JSON.stringify(response));
                if (responseObject && responseObject.body) {
                    this.StripePublishableKey = responseObject.body.PublishableKey || this.StripePublishableKey;
                    this.initialiseStripe(this.StripePublishableKey);
                    let paymentItem: any = {};
                    this.customerCardDetails = [];
                    for (let i = 0; i < this.CustomerPaymentKeyDetails.length; i++) {
                        paymentItem = this.CustomerPaymentKeyDetails[i];
                        this.stripeObject.stripe.retrieveSource({
                            id: paymentItem.SourceId,
                            client_secret: paymentItem.ClientSecret,
                        }).then((result: any) => {
                            if (result) {
                                result.source.StripeCustomerId = paymentItem.StripeCustomerId;
                                this.customerCardDetails.push(result.source);
                            }
                        });
                    }
                }
            }, err => {
                console.log("ERROR IN getStripeKey:::", err);
            });
        } catch (e) {
            console.log("ERROR in stripePayment:::::::::", e);
        }
    }

    initialiseStripe(stripeKey: any) {
        var style = {
            base: {
                // Add your base input styles here. For example:
                fontSize: '16px',
                color: '#32325d',
            }
        };
        var stripe = Stripe(stripeKey);
        var elements = stripe.elements();
        // Create an instance of the card Element.
        var card = elements.create('card', { style: style });

        // Add an instance of the card Element into the `stripeCardElement` <div>.
        card.mount('#stripeCardElement');
        this.stripeObject.stripe = stripe;
        this.stripeObject.card = card;
        this.parent.currentPageLoading = false;
    }

    submitPayment() {
        this.parent.loaderMessage = this.staticData.paymentLoading;
        this.parent.gif = this.staticData.paymentLoadingGifName;
        this.parent.currentPageLoading = true;
        //// Create a token or display an error when the form is submitted.
        if (this.paymentMode && this.paymentMode.type == "visa") {
            this.stripeObject.stripe.createToken(this.stripeObject.card).then((result: any) => {
                if (result.error) {
                    // Inform the customer that there was an error.
                    var errorElement = document.getElementById('stripeCardErrors');
                    errorElement.textContent = result.error.message;
                    this.parent.currentPageLoading = false;
                } else {
                    if (result && result.token && result.token.id) {
                        this.stripeObject.stripe.createSource(this.stripeObject.card, {
                            type: 'card'
                        }).then((result1) => {
                            if (result1) {
                                this.stripeObject.source_id = result1.source.id;
                                this.stripeObject.client_secret = result1.source.client_secret;
                                this.createStripeCustomer(result.token);
                            }
                        });
                    }
                }
            });
        } else if (this.paymentMode && this.paymentMode.type == "card" && this.paymentMode.StripeCustomerId){
            this.makePayment(this.paymentMode.StripeCustomerId);
        }
    }

    createStripeCustomer(token) {
        // Submit token ID to the server
        if (token && token.id) {
            var userEmail = this.globalConfig.getCookie('UserName');
            var submitCardToken = {
                "EmailId": userEmail,
                "CardToken": token.id,
                "SubscriptionId": CONSTANTS.SUBSCRIPTIONID,
                "ClientSecret": this.stripeObject.client_secret,
                "SourceId": this.stripeObject.source_id
            };
            this.InvoicePaymentsService.createStripeCustomer(submitCardToken).subscribe(response => {
                let responseObject = JSON.parse(JSON.stringify(response));
                if (responseObject && responseObject.status == 200 && responseObject.body) {
                    if (responseObject.body.StripeCustomerId) {
                        this.makePayment(responseObject.body.StripeCustomerId);
                    }
                }
            }, error => {
                console.log("ERROR in createStripeCustomer::::", error);
            });
        }
    }

    makePayment(StripeCustomerId: any) {
        if (StripeCustomerId) {
            var submitData = {
                "InvoiceId": this.selectedPendingInvoice.Invoice_Id,
                "StripeCustomerId": StripeCustomerId,
                "Amount": this.selectedPendingInvoice.AmountBilled
            };
            this.InvoicePaymentsService.stripeChargeCustomer(submitData).subscribe(response => {
                let responseObject = JSON.parse(JSON.stringify(response));
                if (responseObject && responseObject.body && responseObject.body.StatusCode == 200) {
                    this.paymentErrorOccurred = false;
                    this.dialogReferences.paymentDialogHandler.close();
                    this.parent.currentPageLoading = false;
                    document.getElementById("openPaymentStatusPopup").click();
                    this.getInvoices();
                    //this.pendingInvoiceDetails = [];
                    //this.selectedPendingInvoice = {};
                    //this.alertMessageDetails = {};
                    //this.loadAllPayments("duringPayment");
                    setTimeout(() => {
                        this.dialogReferences.paymentSummaryDialogHandler.close();
                    }, 5000);
                } else {
                    this.paymentErrorOccurred = true;
                    this.dialogReferences.paymentDialogHandler.close();
                    this.parent.currentPageLoading = false;
                    document.getElementById("openPaymentStatusPopup").click();
                }
            }, err => {
                console.log("ERROR IN StripeChargeCustomer:::", err);
                this.paymentErrorOccurred = true;
                this.dialogReferences.paymentDialogHandler.close();
                this.parent.currentPageLoading = false;
                document.getElementById("openPaymentStatusPopup").click();
            });
        }
    }

    getCustomerCardDetails(customerId: any) {
        if (!this.CustomerPaymentKeyDetails || this.CustomerPaymentKeyDetails.length == 0) {
            this.InvoicePaymentsService.getCustomerPaymentDetails(customerId).subscribe(response => {
                let responseObject = JSON.parse(JSON.stringify(response));
                if (responseObject && responseObject.body && responseObject.status == 200) {
                    this.CustomerPaymentKeyDetails = responseObject.body || [];
                }
            }, err => {
                console.log("ERROR IN StripeChargeCustomer:::", err);
            });
        }
    }

    //sendToServer() {
    //    this.InvoicePaymentsService.getPaymentTokenFromServer().subscribe(resp => {
    //        if (resp.status == 200) {
    //            this.BRAINTREE_JWT_TOKEN = resp.body || '';
    //            this.createBrainTreePaymentConnection();
    //        }
    //    }, error => {
    //        if (error.error) {
    //            this.BRAINTREE_JWT_TOKEN = error.error.text || '';
    //            this.createBrainTreePaymentConnection();
    //        }
    //    });
    //}

    // sending nonce to server
    //sendNonceToServer(paymentNonce: string) {
    //    if (paymentNonce) {
    //        if (this.pendingInvoiceDetails && this.pendingInvoiceDetails.isPaymentMethodSaved) {
    //            this.chargeAmountToCustomer();
    //        } else {
    //            let nonceObj = {
    //                "CustomerId": this.pendingInvoiceDetails.Customer_Id,
    //                "PaymentMethodNonce": paymentNonce
    //                //'fake-valid-nonce'
    //            };
    //            this.InvoicePaymentsService.createCustomerGateway(nonceObj).subscribe(response => {
    //                let responseObject = JSON.parse(JSON.stringify(response));
    //                if (responseObject.body && responseObject.body.Status) {
    //                    this.chargeAmountToCustomer();
    //                } else {
    //                    this.paymentErrorOccurred = true;
    //                    this.dialogReferences.paymentDialogHandler.close();
    //                  this.parent.currentPageLoading = false;
    //                    document.getElementById("openPaymentStatusPopup").click();
    //                }
    //            }, err => {
    //                console.log("ERROR IN createCustomerGateway:::", err);
    //                this.paymentErrorOccurred = true;
    //                this.dialogReferences.paymentDialogHandler.close();
    //                this.parent.currentPageLoading = false;
    //                document.getElementById("openPaymentStatusPopup").click();
    //            });
    //        }
    //    }
    //}

    // charge amount to customer API
    //chargeAmountToCustomer() {
    //    let amountChargedToCustomer = {
    //        "CustomerId": this.selectedPendingInvoice.Customer_Id,
    //        "Amount": this.selectedPendingInvoice.AmountBilled,
    //        "InvoiceId": this.selectedPendingInvoice.Invoice_Id
    //    };
    //    this.InvoicePaymentsService.chargeCustomerAmount(amountChargedToCustomer).subscribe(response => {
    //        let responseObject = JSON.parse(JSON.stringify(response));
    //        if (responseObject.body && responseObject.body.Status) {
    //            this.paymentErrorOccurred = false;
    //            this.dialogReferences.paymentDialogHandler.close();
    //          this.parent.currentPageLoading = false;
    //            document.getElementById("openPaymentStatusPopup").click();
    //            this.pendingInvoiceDetails = [];
    //            this.selectedPendingInvoice = {};
    //            this.alertMessageDetails = {};
    //            this.loadAllPayments("duringPayment");
    //            setTimeout(() => {
    //              this.dialogReferences.paymentSummaryDialogHandler.close();
    //            }, 5000);
    //        } else {
    //            this.paymentErrorOccurred = true;
    //            this.dialogReferences.paymentDialogHandler.close();
    //          this.parent.currentPageLoading = false;
    //            document.getElementById("openPaymentStatusPopup").click();
    //        }
    //    }, err => {
    //        this.paymentErrorOccurred = true;
    //        this.dialogReferences.paymentDialogHandler.close();
    //        this.parent.currentPageLoading = false;
    //        document.getElementById("openPaymentStatusPopup").click();
    //    });
    //}

    // creating Brain tree connection
    //createBrainTreePaymentConnection() {
    //    let button = document.querySelector('#submitBrainTreePayment');
    //    if (this.BRAINTREE_JWT_TOKEN) {
    //        braintree.dropin.create({
    //            authorization: this.BRAINTREE_JWT_TOKEN,
    //            //'sandbox_g42y39zw_348pk9cgf3bgyw2b',
    //            container: '#paymentOptionsContainer'
    //        }, (createErr: any, instance: any) => {
    //            this.parent.currentPageLoading = false;
    //            this.paymentInstance = instance;
    //            button.removeEventListener("click", () => { });
    //            button.addEventListener('click', (e) => {
    //                e.stopImmediatePropagation();
    //                e.stopPropagation();
    //              this.parent.currentPageLoading = true;
    //              this.parent.loaderMessage = this.staticData.paymentLoading;
    //              this.parent.gif = this.staticData.paymentLoadingGifName;
    //              instance = this.paymentInstance;
    //                instance.requestPaymentMethod((err: any, payload: any) => {
    //                    if (payload && payload.nonce) {
    //                        this.sendNonceToServer(payload.nonce);
    //                    } else if (err) {
    //                        console.log("ERROR IN PAYMENT::::::::::", err);
    //                        this.paymentErrorOccurred = true;
    //                      this.parent.currentPageLoading = false;
    //                    }
    //                });
    //            });
    //        });
    //    }
    //}

    changeStartDate(dateObject: string) {
        if (dateObject) {
            this.paymentHistoryDateDetails.StartDateModel = dateObject;
        }
    }

    changeEndDate(dateObject: string) {
        if (dateObject) {
            this.paymentHistoryDateDetails.EndDateModel = dateObject;
        }
    }

    //Get All Payments for Payment History
    loadAllPayments(loaderFlag:string) {
      if (this.paymentHistoryDateDetails.StartDateModel && this.paymentHistoryDateDetails.EndDateModel) {
        if (loaderFlag == "duringPayment") {
            this.parent.gif = this.staticData.paymentLoadingGifName;
        } else {
            this.parent.gif = this.staticData.alohaLoadingGifName;
            this.parent.loaderMessage = "";
            this.parent.currentPageLoading = true;
        }
            var paymentHistoryRequestPayload = {
                "StartDate": this.paymentHistoryDateDetails.StartDateModel,
                "EndDate": this.paymentHistoryDateDetails.EndDateModel,
                "SubscriptionId": CONSTANTS.SUBSCRIPTIONID
            };
            this.InvoicePaymentsService.getAllPayments(paymentHistoryRequestPayload)
                .subscribe(response => {
                    if (response && response.body) {
                        this.paymentHistoryArray = response.body;
                        if (this.paymentHistoryArray && this.paymentHistoryArray.length > 0) {
                            this.paymentHistoryArray.sort((a, b) => {
                                return <any>new Date(b.PaymentDate) - <any>new Date(a.PaymentDate);
                            });
                            this.paymentHistoryArray.forEach(obj => {
                                obj.PaymentDateConverted = this.dateConversions.convertDateToMMDDYYYY(obj.PaymentDate);
                                obj.AmountPaid = parseFloat(obj.AmountPaid).toFixed(2);
                            });
                        }
                        if ((this.paymentHistoryArray && this.paymentHistoryArray.length > 0) ||
                            (this.pendingInvoiceDetailsCopy && this.pendingInvoiceDetailsCopy.length > 0)) {
                            this.noRecordsAvailable = false;
                        } else {
                            this.noRecordsAvailable = true;
                        }
                      this.parent.currentPageLoading = false;
                    }
                }, error => {
                    console.log("ERROR IN getAllPayments:::", error);
                });
        }
    }

    getInvoices() {
        //Get All Invoices
        //this.InvoicePaymentsService.getPendingInvoice(CONSTANTS.SUBSCRIPTIONID)
        this.InvoicePaymentsService.getAllInvoices(CONSTANTS.SUBSCRIPTIONID)
            .subscribe(response => {
                if (response && response.body) {
                    this.pendingInvoiceResponse = response.body;
                    if (this.pendingInvoiceResponse && this.pendingInvoiceResponse.length > 0) {
                        this.pendingInvoiceDetails = this.pendingInvoiceResponse;
                        let isPendingInvoice = false;
                        let checkDifferenceOfDates = 0;
                        let alertMessageStatus = "";
                        for (var i = 0; i < this.pendingInvoiceDetails.length; i++) {
                            this.pendingInvoiceDetails[i].StatementDate = this.dateConversions.convertDateToMMDDYYYY(this.pendingInvoiceDetails[i].InvoiceGeneratedDate);
                            this.pendingInvoiceDetails[i].DueDateConverted = this.dateConversions.convertDateToMMDDYYYY(this.pendingInvoiceDetails[i].DueDate);
                            this.pendingInvoiceDetails[i].AmountBilled = parseFloat(this.pendingInvoiceDetails[i].AmountBilled).toFixed(2);
                            if (this.pendingInvoiceDetails[i].IsPending) {
                                isPendingInvoice = true;
                            }
                            checkDifferenceOfDates = 0;
                            if (this.pendingInvoiceDetails[i].PaymentDisplayText == "Payment Due") {
                                checkDifferenceOfDates = this.dateConversions.getNumberOfDaysBetweenDates(<any>new Date(), this.pendingInvoiceDetails[i].DueDate);
                                if (checkDifferenceOfDates > 0) {
                                    this.pendingInvoiceDetails[i].PaymentDisplayText = "Past Payment Due";
                                    alertMessageStatus = "PAST_DUE";
                                } else if (checkDifferenceOfDates <= 0) {
                                    alertMessageStatus = alertMessageStatus ? alertMessageStatus : "DUE";
                                }
                            }
                        }
                        if (isPendingInvoice) {
                            this.alertMessageDetails = this.paymentStatusClv[alertMessageStatus];
                        }
                        this.pendingInvoiceDetails.sort((a, b) => {
                            return <any>new Date(b.InvoiceGeneratedDate) - <any>new Date(a.InvoiceGeneratedDate);
                        });
                        this.pendingInvoiceDetailsCopy = JSON.parse(JSON.stringify(this.pendingInvoiceDetails));
                        this.filterInvoices();
                        this.getCustomerCardDetails(CONSTANTS.SUBSCRIPTIONID);
                    }
                }
                this.loadAllPayments('');
            }, error => {
                console.log("ERROR IN getPendingInvoice:::", error);
                this.loadAllPayments('');
            });
    }

    ngOnInit() {
        var tempSubscriptionId = this.globalConfig.getCookie('SubscricptionId') || CONSTANTS.SUBSCRIPTIONID;
        CONSTANTS.SUBSCRIPTIONID = tempSubscriptionId;
        this.sixMonthPreviousDate.setMonth(this.sixMonthPreviousDate.getMonth() - 6);
        this.paymentHistoryDateDetails.StartDateModel = this.sixMonthPreviousDate;
        this.getInvoices();
    }
}
