<!--Component Header-->
<component-header></component-header>
<!--end of Component Header-->
<!--Invoice and Payments Html-->
<div class="abaBody_Container abaCustomContainer">
  <!--Invoice Header-->
  <div class="abaCustomHeader">
    <label class="abaHeader5 abaBold">Invoices & Payments</label>
  </div>
  <!--end of Invoice Header-->
  <div *ngIf="noRecordsAvailable==false">
    <div *ngIf="pendingInvoiceDetailsCopy && pendingInvoiceDetailsCopy.length>0">
      <!--Payment Alert Content-->
      <div class="paymentAlertCard abaMrgn_tp_20" [ngClass]="alertMessageDetails.borderColor" *ngIf="alertMessageDetails.message">
        <i class="abaIcon abaPdng_tp_5" [ngClass]="alertMessageDetails.color" icon-name="iWarn-md"></i>
        <label class="abaLabel abaPdng_lft_15">
          <span class="abaHeader6" [ngClass]="alertMessageDetails.color">{{alertMessageDetails.title}}</span>
          <span class="d-block abaPdng_tp_10 text-justify">{{alertMessageDetails.message}}</span>
        </label>
      </div>
      <!--end of Payment Alert Content-->
      <!--Pending Invoices Table-->
      <div>
        <header class="abaHeader5 abaBold abaPdng_tp_20 abaPdng_btm_10 d-inline-block">Invoices</header>
        <label class="abaSwitch_autoWidth " id="toggleInvoiceView">
          <input type="checkbox" [(ngModel)]="showPendingInvoices" (change)="filterInvoices()" />
          <span data-on="Pending" data-off="Show all"></span>
        </label>
      </div>
      <div class="row">
        <div class="col-12 abaTableParent paymentHistoryTable" id="invoicesTable">
          <div class="abaTable">
            <div class="abaTableHeader">
              <div class="abaTh col-1 col-md-2 col-xl-1">Invoice Date</div>
              <div class="abaTh col-1 col-md-2 col-xl-2">Statement Number</div>
              <div class="abaTh col-1 col-md-2 col-xl-1 d-none d-xl-inline-block">Type</div>
              <div class="abaTh col-1 col-md-2 col-xl-1">Due Date</div>
              <div class="abaTh col-1 col-md-2 col-xl-2">Status</div>
              <div class="abaTh col-1 col-md-2 col-xl-1">Amount</div>
              <div class="abaTh col-2 col-md-2 col-xl-2">Action</div>
            </div>
            <div class="abaTableBody">
              <div class="abaTableData" *ngFor="let pendingInvoiceItem of pendingInvoiceDetails">
                <div class="abaTd col-1 col-md-2 col-xl-1">{{pendingInvoiceItem.StatementDate}}</div>
                <div class="abaTd col-1 col-md-2 col-xl-2" (click)="openPendinginvoice(Pending_paymentInvoicePreview,  pendingInvoiceItem.Invoice_Id)"><span class="abaLink invoiceStatementNumber">{{pendingInvoiceItem.StatementNumber}}</span></div>
                <div class="abaTd col-1 col-md-2 col-xl-1 d-none d-xl-inline-block">Charge</div>
                <div class="abaTd col-1 col-md-2 col-xl-1">{{pendingInvoiceItem.DueDateConverted}}</div>
                <div class="abaTd col-1 col-md-2 col-xl-2">{{pendingInvoiceItem.PaymentDisplayText}}</div>
                <!--<div class="abaTd col-1 col-md-2 col-xl-2 abaError" *ngIf="pendingInvoiceItem.IsPending">
                  <span class="d-flex">Payment Due<i class="abaIcon abaError mt-1 ml-1" icon-name="iWarn-sm"></i></span>
                </div>
                <div class="abaTd col-1 col-md-2 col-xl-1" *ngIf="!pendingInvoiceItem.IsPending">
                  <span class="d-flex">Paid<i class="abaIcon abaSuccess mt-1 ml-1" icon-name="iCheckCircle-sm"></i></span>
                </div>-->
                <div class="abaTd col-1 col-md-2 col-xl-1">${{pendingInvoiceItem.AmountBilled}}</div>
                <div class="abaTd col-2 col-md-2 col-xl-2 abaPdng_tp_8 " *ngIf="pendingInvoiceItem.IsPending"><button class="btn_Invoice" mat-flat-button (click)="openPaymentPopup(paymentDetails, pendingInvoiceItem)">Make Payment</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end of Pending Invoices Table-->
    </div>
    <!--Payment History-->
    <div class="paymentHistoryContent" *ngIf="paymentHistoryArray && paymentHistoryArray.length>0">
      <header class="abaHeader5">Payment History</header>
      <!--Payment Date Filter-->
      <div class="row">
        <div class="col-md-5 col-xl-2">
          <aba-datepicker abaId="paymentHistoryStartDate" [datepickerPlaceholder]="paymentHistoryDateDetails.StartDatePlaceholder" [datepickerRequired]="true" [maxDate]="paymentHistoryDateDetails.StartDateMaxDate" [minDate]="paymentHistoryDateDetails.StartDateMinDate" [datepickerDisabled]="false" [datepickerModel]="paymentHistoryDateDetails.StartDateModel" (datepickerChange)="changeStartDate($event)"></aba-datepicker>
        </div>
        <div class="col-md-5 col-xl-2">
          <aba-datepicker abaId="paymentHistoryEndDate" [datepickerPlaceholder]="paymentHistoryDateDetails.EndDatePlaceholder" [datepickerRequired]="true" [maxDate]="paymentHistoryDateDetails.EndDateMaxDate" [minDate]="paymentHistoryDateDetails.EndDateMinDate" [datepickerDisabled]="false" [datepickerModel]="paymentHistoryDateDetails.EndDateModel" (datepickerChange)="changeEndDate($event)"></aba-datepicker>
        </div>
        <div class="col-md-2 col-xl-1">
          <button mat-flat-button (click)="loadAllPayments()">Filter</button>
        </div>
      </div>
      <!--end of Payment Date Filter-->
      <!--Invoices Payment Table-->
      <div class="row">
        <div class="col-md-12">
          <div class="abaTableParent" [ngClass]="{'paymentInvoiceTable':(pendingInvoiceDetails&&pendingInvoiceDetails.PaymentStatus)}">
            <div class="abaTable">
              <div class="abaTableHeader">
                <div class="abaTh col-2 col-md-3 col-xl-2"><span class="d-inline-flex"><label>Payment Date</label><i class="abaImgIcon abaCustomTopMrgn abaCustomLeftMrgn" icon-name="iSort-xs" aba-sort-column [sortKey]="'PaymentDateConverted'" [data]="paymentHistoryArray"></i></span></div>
                <div class="abaTh col-2 col-md-2 col-xl-2">Statement Number</div>
                <div class="abaTh col-2 col-md-3 col-xl-2">Payment Instrument</div>
                <div class="abaTh col-2 col-md-2 col-xl-2 d-none d-xl-inline-block">Transaction Type</div>
                <div class="abaTh col-2 col-md-2 col-xl-2">Payment Method</div>
                <div class="abaTh col-2 col-md-2 col-xl-2"><span class="d-inline-flex"><label>Transaction Amount</label><i class="abaImgIcon abaCustomTopMrgn abaCustomLeftMrgn" icon-name="iSort-xs" aba-sort-column [sortKey]="'AmountPaid'" [data]="paymentHistoryArray"></i></span></div>
              </div>
              <div class="abaTableBody">
                <div class="abaTableData" *ngFor="let i of paymentHistoryArray">
                  <div class="abaTd col-2 col-md-3 col-xl-2">{{i.PaymentDateConverted}}</div>
                  <div class="abaTd col-2 col-md-2 col-xl-2" (click)="openHistoryinvoice(i.PaymentId,i.InvoiceId,History_paymentInvoicePreview)"><span class="abaLink invoiceStatementNumber">{{i.StatementNumber}}</span></div>
                  <div class="abaTd col-2 col-md-3 col-xl-2">Ending in {{i.PaymentInstrument}}</div>
                  <div class="abaTd col-2 col-md-2 col-xl-2 d-none d-xl-inline-block">Charge</div>
                  <div class="abaTd col-2 col-md-2 col-xl-2">{{i.PaymentMethod}}</div>
                  <div class="abaTd col-2 col-md-2 col-xl-2">${{i.AmountPaid}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end of Invoices Payment Table-->
    </div>
    <!--end of Payment History-->
  </div>
  <!--No Records Content-->
  <div class="noRecordsContainer" *ngIf="noRecordsAvailable">
    <div class="notFoundContent">
      <div class="notFoundData">
        <i class="abaImgIcon64" icon-name="iNoRecords-xl"></i>
        <label class="abaHeader4">No records available!</label>
        <label class="abaHeader7 abaInfoLabel text-center">You do not have any pending or paid invoices</label>
      </div>
    </div>
  </div>
  <!--end of No Records Content-->
</div>
<!--end of Invoice and Payments Html-->

<ng-template #paymentDetails>
  <div class="aba_modalTitle">
    <header class="abaHeader4 d-inline">Payment Details</header>
    <i class="abaIcon abaPdng_tp_8 float-right abaPrimaryIcon" icon-name="iLock-md"></i>
  </div>
  <div class="aba_modalBody">
    <div class="row pb-2">
      <div class="col-xl-5">
        <label class="abaLabel">Amount to be Paid :<span> ${{selectedPendingInvoice.AmountBilled}}</span></label>
      </div>
    </div>
    <div class="row ml-0">
      <div class="col-xl-5 ababrdr_btm_1">
      </div>
    </div>
    <header class="abaHeader6 abaPdng_tp_5">Select Payment Method</header>
    <div class="abaPdng_tp_15">
      <!--<mat-radio-group aria-label="Select an option" [(ngModel)]="paymentMode">
        <mat-radio-button *ngFor="let paymentModeType of paymentModeTypes;let i = index" id="{{paymentModeType.name}}" name="payment" [value]="paymentModeType" (click)="changePaymentType(paymentModeType,$event)">
          <a class="abaPdng_tp_10 abaPdng_rgt_30">
            <span *ngIf="paymentModeType.name!='Visa'">{{paymentModeType.name}}</span>
            <img src="../../../assets/SASS/Images/visa.png" class="img-fluid abaPdng_rgt_5" alt="visa image" *ngIf="paymentModeType.name=='Visa'" />
            <img src="../../../assets/SASS/Images/mastercard.png" class="img-fluid abaPdng_rgt_5" alt="mastercard image" *ngIf="paymentModeType.name=='Visa'" />
            <img src="../../../assets/SASS/Images/american.png" class="img-fluid abaPdng_rgt_5" alt="american card image" *ngIf="paymentModeType.name=='Visa'" />
            <img src="../../../assets/SASS/Images/discover.png" class="img-fluid" alt="discover card image" *ngIf="paymentModeType.name=='Visa'" />
          </a>
        </mat-radio-button>
      </mat-radio-group>-->
      <mat-radio-group aria-label="Select an option" [(ngModel)]="paymentMode" id="paymentMethods">
        <mat-radio-button id="{{paymentModeType.name}}" name="payment" [value]="paymentModeType" (click)="changePaymentType(paymentModeType,$event)">
          <a class="abaPdng_tp_10 abaPdng_rgt_30">
            <span *ngIf="paymentModeType.name!='Visa'">{{paymentModeType.name}}</span>
            <img src="../../../assets/SASS/Images/visa.png" class="img-fluid abaPdng_rgt_5" alt="visa image" *ngIf="paymentModeType.name=='Visa'" />
            <img src="../../../assets/SASS/Images/mastercard.png" class="img-fluid abaPdng_rgt_5" alt="mastercard image" *ngIf="paymentModeType.name=='Visa'" />
            <img src="../../../assets/SASS/Images/american.png" class="img-fluid abaPdng_rgt_5" alt="american card image" *ngIf="paymentModeType.name=='Visa'" />
            <img src="../../../assets/SASS/Images/discover.png" class="img-fluid" alt="discover card image" *ngIf="paymentModeType.name=='Visa'" />
          </a>
          <div id="stripeCardElement" class="abaMrgn_tp_10">
            <!-- Elements will create input elements here -->
          </div>
          <!-- Display error messages in this element -->
          <div id="stripeCardErrors" role="alert" class="abaError"></div>
        </mat-radio-button>
        <mat-radio-button name="payment" *ngFor="let customerCardItem of customerCardDetails;let i = index" [value]="customerCardItem" (click)="changePaymentType(customerCardItem,$event)">
          <header class="abaHeader6 abaPdng_tp_15" *ngIf="i == 0">Saved Cards</header>
          <div class="abaMrgn_tp_15">
            <div (click)="submitPayment(i)" class="savedCardNumber d-inline-block">
              <img src="../../../assets/SASS/Images/visa.png" class="img-fluid abaPdng_rgt_5" alt="visa image" *ngIf="customerCardItem.card.brand == 'Visa'" />
              <img src="../../../assets/SASS/Images/mastercard.png" class="img-fluid abaPdng_rgt_5" alt="mastercard image" *ngIf="customerCardItem.card.brand == 'MasterCard'" />
              <img src="../../../assets/SASS/Images/american.png" class="img-fluid abaPdng_rgt_5" alt="american card image" *ngIf="customerCardItem.card.brand == 'American Express'" />
              <img src="../../../assets/SASS/Images/discover.png" class="img-fluid abaPdng_rgt_5" alt="discover card image" *ngIf="customerCardItem.card.brand == 'Discover'" />
              **** **** **** {{customerCardItem.card.last4}}
            </div>
            <div class="abaMrgn_lft_15 d-inline-block">Expires on {{customerCardItem.card.exp_month}}/{{customerCardItem.card.exp_year}}</div>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div id="paymentOptionsContainer"></div>
  </div>
  <div class="aba_modalFooter text-right">
    <button mat-stroked-button matDialogClose class="abaMrgn_rgt_15">Discard</button>
    <button mat-flat-button id="submitBrainTreePayment" (click)="submitPayment()">Proceed</button>
  </div>
</ng-template>
<button mat-flat-button class="d-none" id="openPaymentStatusPopup" (click)="openPaymentStatusDialog(paymentStatusMessage)">Confirm</button>
<ng-template #paymentStatusMessage>
  <div class="aba_modalTitle" id="paymentConfirmationPopupTitle">
    <header class="abaHeader4 d-inline">Payment Confirmation</header>
    <i class="abaIcon abaPdng_tp_8 float-right" icon-name="iClose-md" mat-dialog-close></i>
  </div>
  <div class="aba_modalBody aba_CustomPopupHeight">
    <div class="notFoundContent">
      <div class="notFoundData">
        <i class="abaIcon abaPdng_tp_8 abaSuccess" icon-name="iCheckCircle-md" *ngIf="!paymentErrorOccurred"></i>
        <i class="abaIcon abaPdng_tp_8 abaError" icon-name="iCancel-md" *ngIf="paymentErrorOccurred"></i>
        <label class="abaHeader6 abaPdng_tp_20 abaSuccess" *ngIf="!paymentErrorOccurred">Payment Successful.</label>
        <label class="abaHeader6 abaPdng_tp_20 abaError" *ngIf="paymentErrorOccurred">Payment Failed.</label>
      </div>
    </div>
  </div>
</ng-template>

<!--Pending Invoice Preview Dialog-->
<ng-template #Pending_paymentInvoicePreview>
  <div class="aba_modalTitle">
    <header class="abaHeader4 d-inline">Invoice Preview</header>
  </div>
  <div class="aba_modalBody invoice_modalBody">
    <app-invoicepdf [showPaymentReciept]="showPaymentReciept" [pendingInvoiceResponse]="pendingInvoiceResponse" [invoiceFeaturesList]="invoiceFeaturesList" [invoiceChargesList]="invoiceChargesList"></app-invoicepdf>
  </div>
  <div class="aba_modalFooter text-right">
    <button mat-flat-button class="mr-4" (click)="generateInvoice()">Download</button>
    <button mat-stroked-button matDialogClose>Discard</button>
  </div>
</ng-template>
<!--end of Pending Invoice Preview Dialog-->
<!--History Invoice Preview Dialog-->
<ng-template #History_paymentInvoicePreview>
  <div class="aba_modalTitle">
    <header class="abaHeader4 d-inline">Invoice Preview</header>
  </div>
  <div class="aba_modalBody invoice_modalBody">
    <app-invoicepdf [showPaymentReciept]="!showPaymentReciept" [pendingInvoiceResponse]="pendingInvoiceResponse" [invoiceFeaturesList]="invoiceFeaturesList" [invoiceChargesList]="invoiceChargesList" [paymentReceiptDetails]="paymentReceiptdetails"></app-invoicepdf>
  </div>
  <div class="aba_modalFooter text-right">
    <button mat-flat-button class="mr-4" (click)="paymentReceipt(paymentReceiptdetails.PaymentId,pendingInvoiceResponse.InvoiceDetails[0].Invoice_Id)">Download</button>
    <button mat-stroked-button matDialogClose>Discard</button>
  </div>
</ng-template>
<!--end of History Invoice Preview Dialog-->
