<div>
  <!--App Default View Html-->
  <div class="">
    <div class="d-flex" [ngClass]="{'abaScreenBlur abaBlurFilter':currentPageLoading}" *ngIf="!loadAutoSignup">
      <div class="LeftNavContainer" [ngClass]="{'abaMenuExpand':abaMenuView,'abaMenuToggle_Collapse':!abaMenuView}">
        <div class="row">
          <!--Left navigation Html-->
          <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 pl-0">
            <div class="LeftNavSection">
              <!--Menu Navigation List Html-->
              <nav class="LeftNavbar">
                <ul>
                  <!--User Logo-->
                  <li>
                    <div class="navLogo">
                      <img src="../../../assets/SASS/Images/Aloha.png" *ngIf="!abaMenuView" />
                      <img src="../assets/SASS/Images/alohaWhiteLogo.png" *ngIf="abaMenuView" />
                    </div>

                  </li>
                  <!--end of User Logo-->
                  <!--<li title="DASHBOARD">
                  <a>
                    <i class="abaIcon" icon-name="iDashBoard-md"></i>
                    <label class="abaMenulabel">DASHBOARD</label>
                  </a>
                </li>-->
                  <li title="SUBSCRIPTION" routerLink="/subscription" routerLinkActive="active">
                    <a>
                      <i class="abaImgIcon defaultIcon" icon-name="iSubscription-md"></i>
                      <i class="abaImgIcon ActiveIcon" icon-name="iActiveSubscription-md"></i>
                      <label class="abaMenulabel">SUBSCRIPTION</label>
                    </a>
                  </li>
                  <li title="PAYMENT HISTORY" routerLink="/invoicepayments" routerLinkActive="active">
                    <a>
                      <i class="abaImgIcon defaultIcon" icon-name="iPaymentHistory-md"></i>
                      <i class="abaImgIcon ActiveIcon" icon-name="iActivePaymentHistory-md"></i>
                      <label class="abaMenulabel">PAYMENT HISTORY</label>
                    </a>
                  </li>
                  <!--<li title="PAYMENT METHODS">
                  <a>
                    <i class="abaIcon" icon-name="iPayment-md"></i>
                    <label class="abaMenulabel">PAYMENT METHODS</label>
                  </a>
                </li>
                <li title="LOGIN HISTORY">
                  <a>
                    <i class="abaIcon" icon-name="iActivity-md"></i>
                    <label class="abaMenulabel">LOGIN HISTORY</label>
                  </a>
                </li>-->
                  <!--Aloha ABA Logo-->
                  <li>
                    <div class="navLogo">
                      <img src="../../../assets/SASS/Images/Aloha.png" *ngIf="!abaMenuView" />
                      <img src="../assets/SASS/Images/alohaWhiteLogo.png" *ngIf="abaMenuView" />
                    </div>
                  </li>
                  <!--end of Aloha ABA Logo-->
                </ul>
              </nav>
              <!--end of Menu Navigation List Html-->
            </div>
          </div>
          <!--end of Left navigation Html-->
          <!--Menu Toggle Icon Section-->
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 abaToggleSection">
            <a class="ToggleButton" (click)="abaMenuView = !abaMenuView">
              <i class="abaIcon abaDarkIcon abaMenu_toggleIcon" icon-name="iMenu-sm" *ngIf="!abaMenuView"></i>
              <i class="abaIcon abaDarkIcon abaMenu_toggleIcon" icon-name="iLeftArrow-sm" *ngIf="abaMenuView"></i>
            </a>
          </div>
          <!--end of Menu Toggle Icon Section-->
        </div>
      </div>
      <div class="abaGlobal_Bg" [ngClass]="{'ababodyCollapse':abaMenuView,'abaMenuToggle_Expand':!abaMenuView}">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div class="d-flex" [ngClass]="{'abaScreenBlur abaBlurFilter':currentPageLoading}" *ngIf="loadAutoSignup">
      <!--New Subscriber Landing Html-->
      <new-subscriber class="w-100" *ngIf="loadAutoSignup"></new-subscriber>
      <!--end of New Subscriber Landing Html-->
    </div>
      <app-Loader [isLoading]="currentPageLoading" [message]="loaderMessage" [gif]="gif"></app-Loader>
    </div>
    <!--end of App Default View Html-->    

  </div>
